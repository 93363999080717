/* eslint-disable import/no-anonymous-default-export */
export default [
    {
        title: 'CRM Migration and Customizations',
        imgUrl: '/assets/Salesforce_Migration.png',
        stack: ['Salesforce.com', 'Access Database', 'Office Scripts', 'Salesforce Flow', 'SugarCRM', 'SAP CRM'],
        link: '#',
        blurb: "Whether it is a complex excel document, access database or an existing CRM system, we can help you with customizations or migrations between various CRM systems.",
    },
    {
        title: 'Twilio Emergency Notification System',
        imgUrl: '/assets/twilio.webp',
        stack: ['Excel', 'VBS', 'JavaScript', 'Google Sheets'],
        link: '#',
        blurb: "Created an Excel/VBS based employee emergency notification system for simplifying the process to call/text employees in case of inclement weather or other emergency company closures. Also created a Google Sheets variant to automate Bill Pay reminders for customers that are due soon/past due",
    },
    {
        title: 'Visitor Sign-In and Kiosk Signage',
        imgUrl: '/assets/kiosk.jpg',
        stack: ['HTML', 'CSS', 'JavaScript'],
        link: '#',
        blurb: "Configured a self-service lobby check in kiosk using SwipedOn for visitor tracking and Balena.IO on RaspberryPI for signage. Visitors sign in on a secured iPad, or by scanning a QR code with their cell phone, then SwipedOn prints a visitor badge and notifies employee's via email and SMS that their visitor has arrived. Balena.IO allows remote configuration and management of the content displayed on the digital display.",
    },
    {
        title: 'On-Prem PBX to UCaaS Migration',
        imgUrl: '/assets/uccas.webp',
        stack: ['Python', 'Mitel', 'RingCentral', 'IVR', 'Amazon Poly', 'File conversions'],
        link: '#',
        blurb: "Migration from on-prem Mitel phone system to Avaya's RingCentral UCaaS. This project included negotiating rates, managing backups and exports, using WSL2 and scripting to convert recordings from proprietary audio formats to WAV, automated transcribing recordings, used AWS Polly to create new high quality recordings from transcriptions, and configured queues and IVR's in RingCentral.",
    },
    {
        title: 'General IT Support',
        imgUrl: '/assets/big-logo-large.png',
        stack: ['Active Directory', 'Office 365', 'Licensing', 'Deployments', 'Helpdesk', 'Phone Systems', 'Security Cameras'],
        link: '#',
        blurb: "If you need general IT Support or help with other technology related projects that aren't listed here, we can help. Let us know more information about your situation and we will find various solutions for you.",
    },
    {
        title: 'Web Development',
        imgUrl: '/assets/big-logo-large.png',
        stack: ['HTML', 'CSS', 'Javascript', 'Typescript', 'React', 'WIX/Squarespace', 'Hosting', 'Dashboards'],
        link: '#',
        blurb: "We can provide maintenance on your current website, or build you a new website from scratch. Contact us with your requirements and we will help you with any updates or projects needed.",
    },
    {
        title: 'Teams / SharePoint Customizations',
        imgUrl: '/assets/powerautomate.png',
        stack: ['Workflows', 'Power Automate', 'PowerApps', 'Dataverse', 'Approval Workflows', 'Document Libraries'],
        link: '#',
        blurb: "We can provide assistance with setting up new flows and customizations, or assist in improving current offline processes and migrating them to Teams.",
    },
    {
        title: 'Training and Documentation',
        imgUrl: '/assets/big-logo-large.png',
        stack: ['Knowledge Base', 'Office 365', 'Processes', 'Standardization', 'Learning Management Solutions', 'Video Creation'],
        link: '#',
        blurb: "Training is a crucial component of any organization. Whether you're establishing onboarding and continuous training procedures for the first time or transitioning to a new Learning Management System (LMS), we are here to help. We also offer services to develop guides and train trainers on creating effective training programs and managing the LMS.",
    },
    {
        title: 'Project Management Systems',
        imgUrl: '/assets/big-logo-large.png',
        stack: ['ServiceNow', 'Monday.com', 'LiquidPlanner', 'Jira'],
        link: '#',
        blurb: "Unsure where to start? Want to migrate to a new project managment solution? We can provide research and development on various systems helping you find the best solution for your needs.",
    },
]