/* eslint-disable import/no-anonymous-default-export */
export default [
    {
        title: 'IT Services Consulting',
        answer: ["Why Hire IT Consultants?","IT consultants provide strategic advice aimed at helping your organization leverage technology and IT resources to achieve your business goals. They excel at solving IT problems, ensuring that your technology infrastructure aligns with your strategic objectives.","Consider this: is IT your core competency? For most organizations, IT is a means to an end, while their true value lies elsewhere. Keeping up with the rapidly evolving technology landscape is challenging, which is why hiring IT consultants like Boyes Innovations makes sense. We handle your IT challenges so you can focus on managing your business.","Our IT Consulting Services offer a comprehensive project overview, providing insights into the project's scope, timeline, and potential risks. With our detailed project plan, you can choose to complete the project independently or hire us to implement it for you."],
    },
    {
        title: 'IT Services Implementation',
        answer: ["Boyes Innovations stands out for its deep expertise and proven track record in delivering customized IT solutions tailored to meet the unique needs of each client. Our team of seasoned professionals brings a wealth of experience across various industries, ensuring that we understand the specific challenges and opportunities your business faces. By choosing Boyes Innovations, you gain access to a partner who not only implements cutting-edge technology but also aligns it with your strategic business objectives, driving efficiency, productivity, and growth.","We pride ourselves on our client-centric approach, which places your business goals at the forefront of our services. Our comprehensive implementation process begins with a thorough assessment of your current IT infrastructure and needs, followed by the development of a strategic plan that addresses your specific requirements. Throughout the implementation phase, we maintain transparent communication, providing regular updates and incorporating your feedback to ensure the final solution is precisely what your business needs. Our commitment to excellence and customer satisfaction guarantees that your IT projects are completed on time, within budget, and with minimal disruption to your operations.","Furthermore, Boyes Innovations offers unparalleled post-implementation support, ensuring that your new IT systems are seamlessly integrated and optimized for long-term success. We provide ongoing maintenance, training, and support services to ensure that your team is fully equipped to utilize the new technology effectively. By partnering with Boyes Innovations, you not only receive top-notch IT implementation services but also a long-term ally dedicated to helping your business adapt and thrive in an ever-evolving technological landscape. Choose Boyes Innovations for a seamless, efficient, and future-proof IT services implementation."],
    },
    // {
    //     title: 'General IT Support Services',
    //     answer: ["If your small or medium-sized business is like most, you face scalability challenges with your IT infrastructure. Your IT needs vary throughout the year, and while you typically have sufficient staff and budget to manage, there are times when you feel overwhelmed.","During these peak times, it’s impractical to hire and train a large in-house team to handle all potential IT challenges year-round. This is where Boyes Innovations’s IT Support Services come in.","We provide IT support specialists who are ready to step in whenever your IT demands threaten to overwhelm your operations. With Boyes Innovations, you have the flexibility and expertise needed to maintain smooth and efficient IT operations, no matter the season."],
    // },
    {
        title: 'CRM Migration and Customization',
        answer: ['Choosing Boyes Innovations for your CRM migration and customization needs ensures a seamless transition and a system tailored to your unique business requirements. Our team of experts possesses deep knowledge and extensive experience in CRM systems, enabling us to handle migrations with precision and minimal disruption to your operations. We take the time to understand your business processes and goals, ensuring that the new CRM system is configured to support and enhance your operations. Our customized solutions are designed to maximize efficiency, improve customer interactions, and drive business growth.', 'Furthermore, Boyes Innovations offers unparalleled support throughout the entire migration and customization process. We provide comprehensive training for your team, ensuring they are well-equipped to leverage the new system to its fullest potential. Post-migration, our dedicated support team is always available to address any issues and make necessary adjustments to keep your CRM system running smoothly. By choosing Boyes Innovations, you gain a reliable partner committed to delivering a CRM solution that not only meets but exceeds your expectations, empowering your business to achieve greater success.'],
    },
    {
        title: 'IT Project Management',
        answer: ['Choosing Boyes Innovations for your third-party IT project management needs ensures that your projects are managed with the highest level of expertise, efficiency, and precision. Our team of seasoned project managers brings a wealth of experience in overseeing diverse IT projects, ensuring they are delivered on time, within budget, and to your exact specifications. We utilize industry best practices and cutting-edge project management tools to plan, execute, and monitor your projects, providing you with detailed progress reports and transparent communication throughout the process. This ensures that you are always informed and confident in the status and direction of your IT initiatives.', 'Moreover, Boyes Innovations is committed to understanding the unique requirements and goals of your business. We tailor our project management approach to align with your strategic objectives, ensuring that each project supports and enhances your overall business strategy. Our client-centric focus means we work closely with your internal teams and third-party vendors to coordinate efforts, resolve issues promptly, and ensure seamless integration of all project components. By choosing Boyes Innovations, you gain a reliable partner dedicated to driving the success of your IT projects, enabling you to focus on your core business activities with peace of mind.'],
    },
    {
        title: 'Teams / SharePoint Customization and Automations',
        answer: ['Choosing Boyes Innovations for your Microsoft Teams customization and automation needs ensures that you leverage the full potential of this powerful collaboration tool tailored specifically to your business requirements. Our experts have extensive experience in customizing Microsoft Teams to enhance communication, streamline workflows, and improve productivity. We take the time to understand your unique processes and challenges, creating customized solutions that integrate seamlessly with your existing systems. Whether you need bespoke team structures, personalized interfaces, or specific integrations with other tools, Boyes Innovations delivers solutions that fit perfectly with your operational needs.', 'Additionally, our proficiency in automating Microsoft Teams workflows can significantly enhance your efficiency and reduce manual tasks. We design and implement automations that simplify routine activities, improve response times, and ensure that your team can focus on more strategic tasks. Our approach ensures that every automation is reliable, secure, and aligned with your business objectives. By choosing Boyes Innovations, you gain a partner committed to transforming your use of Microsoft Teams into a tailored, automated powerhouse that supports your team’s collaboration and productivity, driving your business towards greater success.'],
    },
    {
        title: 'Software and Application Deployment',
        answer: ['Choosing Boyes Innovations for application development guarantees a solution that is expertly crafted to meet your specific business needs and objectives. Our team of skilled developers brings a wealth of experience and technical expertise to every project, ensuring that your application is built using the latest technologies and best practices. We take a collaborative approach, working closely with you to understand your vision and requirements, and translating them into a functional, user-friendly application. Our commitment to quality and innovation means that your application will not only meet current needs but also be scalable and adaptable to future demands.', 'Furthermore, Boyes Innovations prioritizes a seamless development process from concept to deployment. We provide transparent communication, regular updates, and thorough testing to ensure that your application performs flawlessly across all platforms. Our post-launch support and maintenance services ensure that your application remains up-to-date and continues to deliver value over time. By choosing Boyes Innovations for your application development, you gain a reliable partner dedicated to delivering high-quality, custom solutions that drive your business success and keep you ahead of the competition.'],
    },
    {
        title: 'Web Development',
        answer: ['Choosing Boyes Innovations for website development ensures that you receive a high-quality, tailored solution that perfectly aligns with your business needs and goals. We specialize in implementing self-managed CMS platforms such as Shopify, WordPress, Drupal, and Joomla, providing you with the flexibility to easily update and manage your website content. Our team of experienced developers works closely with you to understand your vision, ensuring that your website is not only visually appealing but also user-friendly and optimized for performance. Whether you need a simple, easy-to-manage site or a complex, fully custom web solution, Boyes Innovations has the expertise to deliver.', 'In addition to our expertise in CMS platforms, Boyes Innovations excels in creating fully custom websites that stand out in a crowded digital landscape. Our custom web development services are designed to provide unique, scalable solutions that cater specifically to your business needs. From bespoke design elements to advanced functionality and integrations, we ensure that your website is a true reflection of your brand and capable of driving engagement and conversions. Our commitment to quality, innovation, and customer satisfaction means that you can trust Boyes Innovations to deliver a website that not only meets but exceeds your expectations, helping your business thrive online.'],
    },
    {
        title: 'Technology Training and Documentation',
        answer: ['Choosing Boyes Innovations for technology training and documentation ensures that your team is fully equipped to leverage new tools and systems efficiently and effectively. Our comprehensive training programs are designed to meet the specific needs of your organization, providing hands-on, practical instruction that enhances your team’s proficiency and confidence. Whether you need training on new software, systems, or processes, our experienced trainers deliver content that is clear, engaging, and easy to understand. We tailor our training sessions to your business context, ensuring that your team can apply their new skills directly to their roles, thereby maximizing productivity and minimizing downtime.', 'In addition to our top-tier training services, Boyes Innovations excels in creating detailed and user-friendly documentation. We understand the importance of having accessible and accurate documentation for reference, troubleshooting, and onboarding. Our documentation services include the creation of manuals, user guides, FAQs, and more, all crafted to meet your specific needs and written in clear, concise language. By choosing Boyes Innovations, you ensure that your team has the resources they need to succeed and that your organization maintains a high level of operational efficiency and knowledge retention. Our commitment to quality training and documentation empowers your team to fully utilize your technology investments, driving better business outcomes and long-term success.'],
    },

]